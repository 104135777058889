import PropTypes from 'prop-types';
import classNames from 'classnames';

import NotificationsDock from 'views/NotificationsDock';

import GuestHeader from '../headers/GuestHeader';

const GuestLayout = ({ children, isGrey, logoHref }) => (
  <div className="guest-layout">
    <GuestHeader logoHref={logoHref} />
    <NotificationsDock />
    <div className={classNames('guest-layout__content', isGrey && 'guest-layout__content--gray')}>
      {children}
    </div>
  </div>
);

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isGrey: PropTypes.bool,
  logoHref: PropTypes.string,
};

GuestLayout.defaultProps = {
  isGrey: true,
  logoHref: null,
};

export default GuestLayout;
