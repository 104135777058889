import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'ramda';

import yup from 'lib/yupLocalised';

import { handleSubmit } from 'utils/form/handleSubmit';

import LoginFormComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class LoginForm extends React.Component {
  render = () => (
    <LoginFormComponent
      {...this.props}
    />
  );
}

export { LoginForm as LoginFormContainer };

export default compose(
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),
    handleSubmit,
    validationSchema: yup.object().shape({
      email: yup.string().isValidEmail().required(),
      password: yup.string().required(),
    }),
  }),
)(LoginForm);

