import PropTypes from 'prop-types';
import Link from 'next/link';
import { Field, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { prop } from 'ramda';

import Alert from 'views/shared/Alert';
import SiteRules from 'views/shared/SiteRules';
import InputField from 'views/shared/InputField';
import PasswordInputField from 'views/shared/PasswordInputField';
import SubmitFormButton from 'views/shared/SubmitFormButton';

const LoginFormComponent = ({
  status,
  redirectToResetPassword,
}) => (
  <>
    <Form className="grow-1">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
            <div className="auth">
              <h1 className="auth__title mb-36">
                <FormattedMessage id="login.loginYourAccount" />
              </h1>
              {prop('base', status) && (
                <Alert
                  className="auth__alert"
                  message={prop('base', status)}
                />
              )}
              <Field
                id="email"
                type="email"
                name="email"
                label={{ id: 'login.email' }}
                component={InputField}
                className="mb-16"
                icon="alert"
                autoComplete="off"
                fullWidth
              />
              <Field
                id="password"
                name="password"
                label={{ id: 'login.password' }}
                component={PasswordInputField}
                className="mb-16"
                icon="alert"
                autoComplete="off"
                visiblePassword
                fullWidth
              />
              <Link href={redirectToResetPassword}>
                <a className="auth__link auth__link--right">
                  <FormattedMessage id="login.forgotPassword" />
                </a>
              </Link>
            </div>
            <div className="auth__btns auth__btns--right">
              <SubmitFormButton
                className="w-160"
                text={{ id: 'shared.next' }}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
    <SiteRules />
  </>
);

LoginFormComponent.defaultProps = {
  status: undefined,
};

LoginFormComponent.propTypes = {
  status: PropTypes.shape(),
  redirectToResetPassword: PropTypes.string.isRequired,
};

export default LoginFormComponent;
