import PropTypes from 'prop-types';

import { LANDING_HOST } from 'constants';
import { IMAGES } from 'constants/platform';

import getLangImage from 'utils/getLangImage';

const GuestHeaderComponent = ({
  logoHref,
  language,
  intl,
}) => (
  <header className="main-header">
    <a className="main-header__logo" href={logoHref || LANDING_HOST}>
      <picture className="landing-header__logo-img">
        <source media="(max-width: 1023.98px)" srcSet={getLangImage(language, `logo/${language}`, IMAGES.logoMobile)} />
        <source srcSet={getLangImage(language, `logo/${language}`, IMAGES.logo)} />
        <img src={getLangImage(language, `logo/${language}`, IMAGES.logo)} alt={intl.formatMessage({ id: 'shared.alt.logo' })} />
      </picture>
    </a>
  </header>
);

GuestHeaderComponent.propTypes = {
  language: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
  logoHref: PropTypes.string,
};

GuestHeaderComponent.defaultProps = {
  logoHref: null,
};

export default GuestHeaderComponent;
