import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';

const SiteRules = () => (
  <div className="container">
    <p className="auth__txt-footer">
      <FormattedMessage
        id="login.privacyPolicyAndTerms"
        values={{
          terms: (
            <Link href="/terms">
              <a className="auth__link">
                <FormattedMessage id="login.terms" />
              </a>
            </Link>
          ),
          privacyPolicy: (
            <Link href="/privacy_policy">
              <a className="auth__link">
                <FormattedMessage id="login.privacyPolicy" />
              </a>
            </Link>
          ),
        }}
      />
    </p>
  </div>
);

export default SiteRules;
