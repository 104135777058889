import React from 'react';

import authenticate from 'lib/authenticate';

import shouldAuthenticate from 'utils/shouldAuthenticate';

import GuestLayoutComponent from './component';

class GuestLayout extends React.Component {
  static getInitialProps = (ctx) => {
    const { pathname } = ctx;

    // eslint-disable-next-line no-useless-return
    if (shouldAuthenticate(pathname) && !authenticate(undefined, ctx)) return;
  };

  render = () => (
    <GuestLayoutComponent
      {...this.props}
    />
  );
}

export default GuestLayout;
